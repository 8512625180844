import React, { useState } from "react";
import Lottie from "lottie-react";
import arrow from './down.json'
import Download from "./Download";
import Team from "./Team";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import Contact from "./Contact";
import { useNavigate } from "react-router";
import Navbar from "./Navbar";
import Donate from "./Donate";

function Question() {

  const navigate=useNavigate()

  function move(){
    navigate('form')
  }

  useEffect(()=>{
    Aos.init({   offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,})
  },[])
  
  return (
<div>
  <Navbar />
  <div className=" md:pb-[300px] pb-[350px] bg-black/40 relative" name='blog' >
  <div className=' w-full h-[240px] bg-green-600'>
       <div className=' flex justify-center items-center font-bold text-3xl pt-28'>
       <h1 className=' md:text-4xl font-extrabold font-mono text-white'>Blog</h1>
       </div>
       <div className=' flex justify-center items-center'>
       <div className=' text-xl font-thin font-serif flex justify-center items-center text-white py-2' >Know More About Our Terms And Conditions </div>
       </div>
       </div>
  <div className=" grid place-items-center h-[100vh] -mt-6 ">
 <div className=" mt-10 mb-[120px]">
<div className=' flex justify-center items-center pt-4 mb-3'>
       <div className='w-[100px] h-[40px] rounded-3xl bg-green-600 font-bold text-white flex justify-center items-center' data-aos='fade-up' ><h1>FAQ</h1></div>
       </div>
       <div className=' flex justify-center items-center font-bold text-3xl text-white'>
       <h1 data-aos='fade-down'>Frequently Asked<spa className=' text-green-600'> Questions</spa></h1>
       </div>
 </div>
    <div className=" w-5/6 md:-mt-28 -mt-[120px]" >
      <div className=" bg-white mb-3 px-5 py-3 shadow-lg rounded-md relative" data-aos='fade-right'>
        <input type="checkbox" name="faq" id="faq1" className=" absolute appearance-none peer" />
        <label for='faq1' className=" flex text-lg items-center font font-semibold after:absolute after:content-['+'] after:rotate-90 after:right-5 after:text-2xl after:text-gray-400 hover:after:text-gray-900 peer-checked:after:transform peer-checked:after:rotate-45">
          <h2 className=" mr-2 h-8 w-8 rounded-full text-white bg-green-600 grid place-items-center">01</h2>
          <h2>What is the beneficts of joining NHCF</h2>
        </label>
        <div className=" mt-5 mr-10 h-0 overflow-hidden transition ease-out duration-500 peer-checked:h-full text-lg">
          <div className=" flex justify-start items-start">
          NHFC has alot of beneficts,such as scholorships, skills training and the rest
          </div>
        </div>
      </div>
      <div className=" bg-white mb-3 px-5 py-3 shadow-lg rounded-md relative" data-aos='fade-right'>
        <input type="checkbox" name="faq" id="faq2" className=" absolute appearance-none peer" />
        <label for='faq2' className=" flex text-lg items-center font font-semibold after:absolute after:content-['+'] after:right-5 after:text-2xl after:text-gray-400 hover:after:text-gray-900 peer-checked:after:transform peer-checked:after:rotate-45">
          <h2 className=" mr-2 h-8 w-8 rounded-full text-white bg-green-600 grid place-items-center">02</h2>
          <h2>Is NHFC for only children and the needy</h2>
        </label>
        <div className=" mt-5 mr-10 h-0 overflow-hidden transition ease-out duration-500 peer-checked:h-full text-lg">
          <div className=" flex justify-start items-start">
          NO. Nectar of Hope for Children Foundation is for everyone
          </div>
        </div>
      </div>
      <div className=" bg-white mb-3 px-5 py-3 shadow-lg rounded-md relative" data-aos='fade-right'>
        <input type="checkbox" name="faq" id="faq3" className=" absolute appearance-none peer" />
        <label for='faq3' className=" flex text-lg items-center font font-semibold after:absolute after:content-['+'] after:right-5 after:text-2xl after:text-gray-400 hover:after:text-gray-900 peer-checked:after:transform peer-checked:after:rotate-45">
          <h2 className=" mr-2 h-8 w-8 rounded-full text-white bg-green-600 grid place-items-center">03</h2>
          <h2>Does it require any payment before joining</h2>
        </label>
        <div className=" mt-5 mr-10 h-0 overflow-hidden transition ease-out duration-500 peer-checked:h-full">
          <div className=" flex justify-start items-start">
         NO. No payment is required for you to join the NHFC
          </div>
        </div>
      </div>
      <div className=" bg-white mb-3 px-5 py-3 shadow-lg rounded-md relative hidden md:block" data-aos='fade-right'>
        <input type="checkbox" name="faq4" id="faq4" className=" absolute appearance-none peer" />
        <label for='faq4' className=" flex text-lg items-center font font-semibold after:absolute after:content-['+'] after:right-5 after:text-2xl after:text-gray-400 hover:after:text-gray-900 peer-checked:after:transform peer-checked:after:rotate-45">
          <h2 className=" mr-2 h-8 w-8 rounded-full text-white bg-green-600 grid place-items-center">04</h2>
          <h2>where is Nectar of Hope for Children foundation located</h2>
        </label>
        <div className=" mt-5 mr-10 h-0 overflow-hidden transition ease-out duration-500 peer-checked:h-full">
          <div>
NHFC is located in Kwame Nkrumah University of Science and Technology(KNUS)
          </div>
        </div>
      </div>
      <div className=" bg-white mb-3 px-5 py-3 shadow-lg rounded-md hidden md:block relative" data-aos='fade-right'>
        <input type="checkbox" name="faq5" id="faq5" className=" absolute appearance-none peer" />
        <label for='faq5' className=" flex text-lg items-center font font-semibold after:absolute after:content-['+'] after:right-5 after:text-2xl after:text-gray-400 hover:after:text-gray-900 peer-checked:after:transform peer-checked:after:rotate-45">
          <h2 className=" mr-2 h-8 w-8 rounded-full text-white bg-green-600 grid place-items-center">05</h2>
          <h2>Is Nector of Hope for children foundation for only student</h2>
        </label>
        <div className=" mt-5 mr-10 h-0 overflow-hidden transition ease-out duration-500 peer-checked:h-full">
          <div>
No. Nectar of Hope for children is for everyone
          </div>
        </div>
      </div>
    </div>
    <div className=' flex justify-center items-center font-bold md:text-3xl text-xl md:py-0 -mt-2 text-white' >
       <h1 data-aos='fade-up'>Download Our<spa className=' text-green-600'> Blog</spa> content HERE</h1>
       </div>
       <div ><Lottie animationData={arrow} color="red" style={{width:'50px',fontWeight:'bold',color:'red'}} /></div>
       <div data-aos='flip-up'>
    <Download />
  </div>
  </div>
  </div>
  <Donate />
</div>

  );
}

export default Question;
