import React, { useRef, useState } from 'react'
import { NavLink,Link } from 'react-router-dom'
import logo from './workimages/WhatsApp Image 2023-10-23 at 4.46.17 PM.jpeg'
import {AiOutlineMenu } from 'react-icons/ai'
import {FaTimes } from 'react-icons/fa'
import {FaChevronUp} from 'react-icons/fa'
import { animateScroll } from "react-scroll";
import './work.css'
import Home from './Home'
import {  Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';


const Navbar = () => {

  const [toggle,settoggle]=useState(false);

const change=()=>{
  settoggle(prev=>!prev)
}

function handleScroll(){
  document.getElementById('service').scrollIntoView({behavior:'smooth'})
}
function handleScroll2(){
  document.getElementById('about').scrollIntoView({behavior:'smooth'})
}
const scrollToTop = () => {
  scroll.scrollToTop();
};

  return (
    <div >
      <div className=' bg-gray-50 py-3 shadow-xl z-50 top-0 relative'  style={{position:'fixed',width:'100%'}} >
      <div className='md:flex md:justify-endl mx-[28px] inline-block'>
      <div className= ' flex justify-start lg:mr-[170px] md:mr-[5px] mr-20 '>
        <Link to='/' className=' lg:font-extrabold lg:font-serif lg:text-2xl text-green-600 pt-1 md:font-thin md:text-lg font-semibold text-lg md:drop-shadow-xl drop-shadow-xl'> Nectar Of Hope for Children's Foundation</Link>
       </div>
     <h5 className=' mx-3 px-5 w-24 h-[37px] pt-1 border-2 rounded-3xl border-gray-700 hover:bg-green-400 duration-700 hidden md:block cursor-pointer '><NavLink to='/home' className='text-black font-mono  text-xl' onClick={scrollToTop} >Home</NavLink></h5> 
     <h5 className=' mx-3 px-5 w-24 h-[37px] pt-1 border-2 rounded-3xl border-gray-700 hover:bg-green-400 duration-700 hidden md:block cursor-pointer '><NavLink to='/about' className='text-black font-mono  text-xl' onClick={scrollToTop}>About</NavLink></h5> 
     <h5 className=' mx-3 px-5 w-36 h-[37px] pt-1 border-2 rounded-3xl border-gray-700 hover:bg-green-400 duration-700 hidden md:block cursor-pointer '><NavLink to='/campaign' className='text-black font-mono  text-xl' onClick={scrollToTop}>Campaigns</NavLink></h5>
     <h5 className='px-5 mx-3 lg:w-[170px] md:w-[220px] h-[37px] pt-1 border-2 rounded-3xl border-gray-700 hover:bg-green-400 duration-700 hidden md:block cursor-pointer '><NavLink to='/contact'  className=' text-black font-mono text-xl' onClick={scrollToTop}>Contact Us</NavLink></h5>  
     <h5 className=' mx-3 px-5 w-24 h-[37px] pt-1 border-2 rounded-3xl border-gray-700 hover:bg-green-400 duration-700 hidden md:block cursor-pointer '><NavLink to='/blog' className='text-black font-mono  text-xl' onClick={scrollToTop}>Blog</NavLink></h5>
     <div>
      <div className='md:hidden flex justify-end items-end relative'>
     <div className=' md:hidden absolute' >
     {
      toggle==true?<FaTimes  onClick={change} size={30} />
      :<AiOutlineMenu  onClick={change}  size={30}/>
    
     }
     <div>
     {
      toggle==true?<div className=' w-[160px] h-[200px] absolute -mx-32 bg-black border-2 rounded-2xl shadow-md duration-700 ease-in-out top-8'>    
<NavLink to='/home' className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base cursor-pointer 'onClick={scrollToTop} >Home</NavLink>
<NavLink to='/About' className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base cursor-pointer ' onClick={scrollToTop} >About</NavLink>
<NavLink to='/campaign' className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base cursor-pointer '  onClick={scrollToTop}>Campaigns</NavLink>
<NavLink to='/Contact' className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base cursor-pointer ' onClick={scrollToTop} >Contact Us</NavLink>
<NavLink to='/blog' className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base cursor-pointer '  onClick={scrollToTop}>Blog</NavLink>
    </div>:<div>{null}</div>
     }
     </div>
     </div>
     </div>
     </div>
      </div>
</div>
{/* <Outlet /> */}
</div>
    
  )
}

export default Navbar
