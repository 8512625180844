import React from 'react'
import { useState,useEffect } from 'react'
import { getDownloadURL,ref } from 'firebase/storage'
import storage from '../config/firebase'
import './download.css'

const Download = () => {
    const [download,setdownload]=useState()

useEffect(()=>{
   const ge=getDownloadURL(ref(storage,'Nectar of Hope Foundation.pdf')).then((url)=>{
        console.log(url);  
        setdownload(url)
    })
},[])

function yeah(){
    return <embed type='application/pdf' src={download} />
}

  return (
    <div>
        <button>
        <a href={download} download='download'>Download</a>
        </button>
    </div>
  )
}

export default Download
