import React, { useEffect, useState } from 'react'
import image1 from './workimages/img113.jpg'
import Loader from './Loader'
import img1 from './workimages/img83.jpg'
import { PiArrowCircleLeftFill } from "react-icons/pi";
import { Outlet, useNavigate } from 'react-router';
import Navbar from './Navbar';
import Donate from './Donate';

const Content1 = () => {

const [loading, setloading]=useState(true)
useEffect(()=>{
  setTimeout(() => {
    setloading(false)
  }, 4000);
})

const nav=useNavigate()
function back(){
nav(-1)
}

  return (
    <div>
      {loading==true?<div className='yeah w-full h-full bg-white'><Loader /></div>:
      <div><Navbar />
      <div className='bg-white flex justify-center items-center md:px-64 px-3'>
      <div  className=' text-xl  text-black text-justify'>
        <div className='  w-full h-[40px] bg-green-600 mb-2 py-32  flex justify-center items-center' style={{width:'100%'}}>
          <div className=' inline-block '><h1 className='md:text-3xl text-2xl font-bold text-center  text-white md:inline-block md:pt-10 pt-16'  >Unveiling Dreams: The Story of Attah Ebenezer</h1></div></div>
      <div className=' flex justify-center items-center'> <img src={img1} className=' md:h-[800px] h-[460px]' style={{width:'100%'}} /></div>
<div className=' py-5 text-xl text-gray-700 font-thin font-serif mb-4'>
In 2017, the foundation had the profound privilege of fulfilling a young boy's cherished dream. 
Despite facing physical challenges, a determined 16-year-old boy harbored a deep-seated 
aspiration—to meet his sports idol, the iconic Asamoah Gyan, the revered former striker and 
captain of the Black Stars football team. His fervent desire was to bask in a personal encounter 
with his athletic hero before embarking on the next chapter of his life's journey
<div className=' py-5'>
Asamoah Gyan, accompanied by his team, graciously responded to the young enthusiast's 
heartfelt wish. The boy's elation was palpable, and the encounter etched an indelible mark upon 
his heart. It was a dream actualized, an extraordinary moment that ignited a profound sense of 
accomplishment and purpose within his soul. His own aspirations began to take shape, with a 
resolute ambition to become a sports analyst in the future.
pursuits were unimpeded.
<div className=' py-5'>Attah Ebenezer, who once traversed the world by crawling, underwent a transformative 
metamorphosis within a year, all thanks to the benevolence of the Nectar of Hope for Children 
Foundation. Providing him with a wheelchair to facilitate his mobility, the Foundation’s
community survey project at Bekwai Feyiase not only attended to the basic educational needs of 
the local children but also made a life-altering impact on Attah's journey. Hailing from the 
underserved community of Bekwai Feyiase, an area devoid of electricity, Attah's life took a 
significant turn when he was enrolled in the Feyiase SDA Basic School, supported by the 
provision of a wheelchair.
</div>
<div className=' py-5'>Fuelled by a relentless pursuit to make Attah's dreams come true, the team diligently orchestrated 
numerous attempts, eventually finding success with the collaborative assistance of Ultimate FM 
radio station. Attah, who had never experienced formal education until his enrollment in 2017 in 
class one, was now able to embrace a brighter future, empowered by the unwavering support of 
the foundation and its collaborative partners</div>
</div>
</div>
      </div>
      </div>
      <Donate />
        </div>}
    </div>
  )
}

export default Content1
