import React, { useState } from "react";
import worker1 from "./workimages/work1.jpg";
import worker2 from './workimages/img121.jpg';
import worker3 from "./workimages/work3.png";
import worker4 from "./workimages/img120.jpg";
import worker5 from "./workimages/img127.jpg";
import worker6 from "./workimages/work6.png";
import Carousel from "./Carousel";
import { LuChevronLeftCircle, LuChevronRightCircle } from "react-icons/lu";
import { RxDotFilled } from "react-icons/rx";
import About from "./About";
import Navbar from "./Navbar";
import Contact from "./Contact";
import logo from './workimages/WhatsApp Image 2023-10-23 at 4.46.17 PM.jpeg'
import president from './workimages/president.jpg'
import co from './workimages/co-preidnet.jpg'
import ceo from './workimages/founder & ceo.jpg'
import finance from './workimages/financial.jpg'
import secetary from './workimages/secretary.jpg'
import vice from './workimages/vice.jpg'
import com from './workimages/photo_2023-11-22_22-23-09.jpg'
import cord from './workimages/male2.jpg'
import prince from './workimages/WhatsApp Image 2023-11-15 at 1.28.59 PM.jpeg'
import ai from './workimages/ai.jpeg';
import coo from './workimages/photo_2023-12-01_11-14-58.jpg'
import Carousel2 from "./Carousel2";
import Donate from "./Donate";
import { useEffect } from "react";
import Aos from "aos";


const Home = () => {
  const images = [
    {
      url: worker1,
    },
    {
      url: worker2,
    },
    {
      url: worker3,
    },
    {
      url: worker4,
    },
    {
      url: worker5,
    },
    {
      url: worker6,
    },
  ];
  const [currentIndex, setcurrentIndex] = useState(0);


  useEffect(()=>{
    Aos.init({   offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,})
  },[])

  const prev = (e) => {
    e.preventDefault();
    const isfirst = currentIndex === 0;
    const newindex = isfirst ? images.length - 1 : currentIndex - 1;
    setcurrentIndex(newindex);
  };

  const next = (e) => {
    e.preventDefault();
    const islast = currentIndex === images.length - 1;
    const newindex = islast ? 0 : currentIndex + 1;
    setcurrentIndex(newindex);
  };
  const message=()=>{
    if(currentIndex==0){
    return <div className=" text-white ">
    <div className=" md:text-7xl text-4xl flex justify-center text-center items-center md:pt-[260px] pt-[240px] ">It's time for beter help</div>
    <div className=" md:pt-3 pt-1 pb-8"> Nothing is small to be offered as help
    
    </div>
    </div>
    
  }if(currentIndex==1){
    return <div className=" text-white">
    <div className=" md:pt-3 pt-1 pb-8"> Nectar of Hope for Children's Foundation. will alwyas put smile on the face of children
     </div>
    </div>
  }
  
  if(currentIndex==2){
    return <div className=" text-white">
    <div className=" md:text-7xl text-4xl flex justify-center text-center items-center md:pt-[260px] pt-[240px] ">Everyday is a brand new day</div>
    <div className=" md:pt-3 pt-1 pb-8"> Tough times never last, but tough people do.Therefore no situatiton is permenent
     </div>
    </div>
  }
  if(currentIndex==3){
    return <div className=" text-white ">
    <div className=" md:text-7xl text-4xl flex justify-center text-center items-center md:pt-[260px] pt-[240px] ">we stop people from given up </div>
    <div className=" md:pt-3 pt-1 pb-8">Hardships often prepare ordinary people for extraordinary destiny.</div>
    </div>
  }
  if(currentIndex==4){
    return <div className=" text-white">
    <div className=" md:text-7xl text-4xl flex justify-center text-center items-center md:pt-[260px] pt-[240px] ">NHCF is always there to help</div>
    <div className="md:pt-3 pt-1 pb-8">NHCF is aimed to puting smile on the face of the needy</div>
    </div>
  }
  if(currentIndex==5){
    return <div className=" text-white">
    <div className=" md:text-7xl text-4xl flex justify-center text-center items-center md:pt-[260px] pt-[240px] ">Tomorrow is always a mystry</div>
    <div className=" md:pt-3 pt-1 pb-8">Nectar of Hope for Children's Foundation is here to make you prepare for your tomorrow</div>
    </div>
  }
}

  return (
    <div>
      <Navbar />
      <div className=" mb-80" >
        <div className="h-[780px]  w-full m-auto relative group">
          <div  name='home' 
            style={{
              backgroundImage: `url(${images[currentIndex].url})`,
              width: "100%",
            }}
            className=" md:h-[540px] h-[450px] bg-center bg-cover duration-700 ease-linear"
          >{message()}
          </div>
          <div className="absolute inset-0 flex items-center justify-between">
            <div
              onClick={prev}
              className="md:mb-[300px] mb-[410px] md:px-[30px] px-[10px] text-white hover:bg-gray"
            >
              <LuChevronLeftCircle style={{ width: "50px", height: "52px" }} />
            </div>
            <div className=" cursor-pointer flex justify-center items-center py-10 md:mt-10 mt-0 md:pb-5 pb-10">
      <a  href="https://forms.gle/Z8xrB6AhJgga4YYv8" className='cursor-pointer flex justify-center items-center w-[120px] md:h-[50px] h-[37px] bg-green-600  border-2 border-white text-gray-100 text-2xl font-bold '>Join Us</a>
  </div>
            <div
              onClick={next}
              className=" md:mb-[300px] mb-[410px] md:px-[30px] px-[10px] text-white hover:bg-gray"
            >
              <LuChevronRightCircle style={{ width: "50px", height: "52px" }} />
            </div>
          </div>
          <div className="flex justify-center items-center gap-2 absolute right-0 left-0 md:top-[440px] top-[398px]" >
            {
              images.map((slide,index)=>(
                <div className=" " key={index}>{currentIndex==index?<div>< RxDotFilled className="text-white" size={52} /></div>:<div id='about'> < RxDotFilled className=" text-white text-opacity-60" size={23}/></div>}</div>
              
              ))
            }
          </div>
          <div className=" bg-white">
          <div className=" flex justify-center items-center" >
            <img src={logo} className=" w-28 h-28 rounded-full mt-20" />
          </div>
          <div className=" text-2xl font-serif font-bold mt-4">Nectar Of Hope For Children Foundation</div>
          <div className=" flex justify-center items-center">
            <div className=" grid md:grid-cols-2 gap-10 md:mx-14 py-[60px]">
              <div className=" bg-green-50" >
                <div className=" text-2xl font-mono py-4">vision</div>
                <div className=" text-lg font-sans pb-4 px-5">Nectar of Hope Foundation envisions a world where every child and individual regardless of 
their circumstances, has access to quality education, healthcare, shelter and nutritious food fostering a society where the Sustainable Development Goals (SDG’s) are realized and potential 
flourishes</div>
              </div>
              <div className=" bg-green-50">
                <div className=" text-2xl font-mono py-4">Mission</div>
                <div className=" text-lg font-sans pb-4 px-5">Nectar of Hope Foundation is dedicated to providing underprivileged children and communities 
with holistic support breaking the cycle of poverty and advancing the SDG’s for a better world. 
Our mission is to be the catalyst for positive change, nurturing hope and building a better 
brighter future for all.</div>
              </div>
            </div>
            </div>
          </div>
          <div className=" flex justify-center items-center bg-green-200 pb-5">
            <div className="">
              <h1 className=" text-4xl font-extrabold font-serif py-3"> Our Services</h1>
              <div className=" text-gray-700 font-semibold md:text-[16px] text-[17px]  pb-5" >
              <ol className=" mb-3 ">1. Offering housing assistance for the homeless or those in unstable living conditions, Supporting affordable housing projects..</ol>
              <ol className=" mb-3"> 2. Providing microloans or grants to support small businesses and entrepreneurs and training programs for business development.</ol>
              <ol className=" mb-3">3.Granting scholarships or financial aid for students and Supporting educational infrastructure and programs.</ol>
              <ol className=" mb-3 md:mx-[196px]">4.Providing direct financial aid to individuals or families facing economic hardship and Offering emergency relief funds for unexpected expenses.</ol>
            </div>
            </div>
          </div>
          <div className=" bg-green-100">
            <div className=" flex justify-center items-center">
<div className="md:grid md:grid-cols-2 pb-6">
  <div>
   
  <div className=" md:text-4xl text-2xl font-bold md:pt-32 pt-16 ">Abdul Rahman <h className=" text-green-600">Ibrahim</h>
  <div className=" text-2xl flex justify-center mt-4 font-thin">Founder</div>
  <div className=" mt-5 text-lg font-thin lg:mx-[200px] text-justify md:px-0 px-6">I believe that Tomorrow is not just another day; it's a blank canvas waiting for your masterpiece. Seize the opportunity, embrace the challenges, and paint a brilliant future.</div>
  </div>
  </div>
  <img src={ceo} className=" md:w-[430px] w-full md:h-[470px] h-[400px] md:py-10 py-7 md:px-0 px-4" />
</div>
</div>
          </div>
          <div className="md:grid md:grid-cols-2 pb-6 bg-green-50 mt-[1px]">
  <div>
  <div className=" md:text-4xl text-2xl font-bold md:pt-32 pt-16 "> Albert Ansah<h className=" text-green-600"> Monny</h>
  <div className=" text-2xl flex justify-center mt-4 font-thin">Co-Founder</div>
  <div className=" mt-5 text-lg font-thin lg:mx-[200px] text-justify md:px-0 px-6">I believe in Doing your little bit of good where you are; it is those little bits of good put together that overwhelm the world</div>
  </div>
  </div>
  <img src={coo} className=" md:w-[430px] w-full md:h-[470px] h-[400px] md:py-10 py-7 md:px-0 px-4 pt-10" />
</div>
          <div className=" bg-white pb-20">
          <div className=" py-4 text-4xl text-gray-800 font-thin font-serif mb-4 pt-16">Discover Our Team</div>
          <div className=" flex justify-center items-center">
            <div className=" md:grid md:grid-cols-4 gap-y-16">
              <div className=" md:mb-0 mb-6">
                <img src={president} className=" mx-8 w-[260px] md:h-[330px] h-[300px]" />
                <div className=" text-lg font-sans font-thin"> Wiliam k. Ahonor</div>
                <div><u><a href='mailto:ahonorwilliam3@gmail.com'>ahonorwilliam3@gmail.com</a></u></div>
                <div className=" text-xl font-thin">President</div>
              </div>
              <div className=" md:mb-0 mb-6">
                <img src={co} className="mx-8 w-[260px] md:h-[330px] h-[300px]" />
                <div className=" text-lg font-sans font-thin">  Thomas Tawiah Baah</div>
                <div><u><a href='baathomastawiah@gmail.com'>baathomastawiah@gmail.com</a></u></div>
                <div className=" text-xl font-thin">Co-President</div>
              </div>
              <div className=" md:mb-0 mb-6">
                <img src={vice} className="mx-8 w-[260px] md:h-[330px] h-[300px]" />
                <div className=" text-lg font-sans font-thin"> Yaw Asante</div>
                <div><u><a href='mailto:asanteyaw600@gmail.com'>asanteyaw600@gmail.com</a></u></div>
                <div className=" text-xl font-thin">Vice President</div>
              </div>
              <div className=" md:mb-0 mb-6">
                <img src={secetary} className="mx-8 w-[260px] md:h-[330px] h-[300px]" />
                <div className=" text-lg font-sans font-thin">  Sarah Amanua Quaye</div>
                <div><u><a href='mailto:squaye074@gmail.com'>squaye074@gmail.com</a></u></div>
                <div className=" text-xl font-thin">secretary</div>
              </div>
              <div className=" md:mb-0 mb-6">
                <img src={finance} className="mx-8 w-[260px] md:h-[330px] h-[300px]" />
                <div className=" text-lg font-sans font-thin"> Sayuti Yusif</div>
                <div><u><a href='mailto:yusifyasayuti1@gmail.com'>yusifyasayuti1@gmail.com</a></u></div>
                <div className=" text-xl font-thin">financial secretary</div>
              </div>
              <div className=" md:mb-0 mb-6">
                <img src={ai} className="mx-8 w-[260px] md:h-[330px] h-[300px]" />
                <div className=" text-lg font-sans font-thin"> Emmanuel Kofi Frimpong</div>
                <div><u><a href='mailto:efrimpong351@yahoo.com'>efrimpong351@gmail.com</a></u></div>
                <div className=" text-xl font-thin">Program Coordinator</div>
              </div>
              <div className=" md:mb-0 mb-6">
                <img src={com} className="mx-8 w-[260px] md:h-[330px] h-[300px]" />
                <div className=" md:mr-0 mx-8 text-lg font-sans font-thin"> Wilhelminaa Naa Tackie</div>
                <div><u className=" md:mr-0 mx-8"><a href='mailto:wilheminatachie8@gmail.com'>wilheminatachie8@gmail.com</a></u></div>
                <div className=" md:mr-0 mx-8 text-xl font-thin">Com.Manager</div>
              </div>
              <div className=" md:mb-0 mb-6">
                <img src={prince} className="mx-8 w-[260px] md:h-[330px] h-[300px]" />
                <div className=" md:mr-0 ml-8 text-lg font-sans font-thin" > Prince Nyame</div>
                <div><u className=" md:mr-0 m-8"><a href='mailto:prince.kojo@gmail.com'>prince.kojonyame@gmail.com</a></u></div>
                <div className=" md:mr-0 ml-8 text-xl font-thin">I.T Manager</div>
              </div>
              </div>
            </div>
          </div>
  <div className=" bg-black/40 md:pb-20 pb-0">
    <div className=" flex justify-center items-center">
    <img src={logo} className=" w-28 h-28 rounded-full mt-10" />
    </div>
    <div className=" text-2xl font-san font-extrabold pb-4 text-white">Concept of Nector Of Hope For Children Foundation</div>
    <div>
    <div className=" flex justify-center items-center">
      <div className=" md:grid md:grid-cols-2 gap-5">
        <div className=" bg-white md:ml-20 md:mb-0 mb-5 md:mx-0 mx-6  rounded-lg" data-aos='zoom-in'>
<div className=" text-lg font-sans pb-4 py-6 px-5">The Nectar of Hope for Children Foundation is a dynamic and impactful nonprofit organization 
dedicated to fostering a positive change in underserved communities nationwide. Established 
with a core commitment to advancing education, healthcare, shelter, food and sustainable 
development goals (SGD’s), our foundation has continually worked to transform lives and build 
a brighter future for children and individuals across the nation.</div>
        </div>
        <div className=" bg-white md:mr-20 md:mb-0 mb-5 md:mx-0 mx-6 rounded-lg" data-aos='zoom-in'>
<div className=" text-lg font-sans pb-16 py-12 px-5">In alignment with our unwavering mission, Nectar of Hope has successfully launched and 
sustained a variety of programs and initiatives aimed at providing amenities to those in need. Our 
efforts have yielded tangible results, empowering communities and catalyzing socio-economic 
progress.</div>
        </div>
        <div className=" bg-white md:ml-20 md:mb-0 mb-5 md:mx-0 mx-6 rounded-lg pb-4" data-aos='zoom-in'>
<div className=" text-lg font-sans pb-4 py-8 px-5">This document serves as a comprehensive overview of the foundation detailing our 
organizational structure, key programs, financial standing, and future plans. As you delve into the 
following pages, you will gain insights into the impacts made, our vision for the future and how 
your support can help us achieve even greater milestones. We invite you to explore our journey, 
learn about our accomplishments and join us in our mission to create a world where every child, 
individual or community has the opportunity to thrive.</div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <Donate />
        </div>
      </div>
    </div>
  );
};

export default Home;
