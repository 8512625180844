import React from 'react'
import { MapContainer,Marker,Popup,TileLayer } from 'react-leaflet'
import l from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useRef} from 'react'

const MyMap = () => {
    const ref=useRef()
    const maker=new l.icon({
        iconUrl:'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png',
        iconSize:[25,35],
        popupAnchor:[65,-65],
       iconAnchor:[-50,70]
    })

  return (
    <div >
      <div className=' relative drop-shadow-2xl shadow-2xl lg:ml-[300px]' style={{zIndex:1}}>
      <MapContainer zoom={13} center={[6.679036,-1.5819858]} ref={ref}  scrollWheelZoom={false}>
<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
<Marker position={[6.6831771,-1.5761459]} icon={maker}>
    <Popup>
<div>NHCF</div>
    </Popup>
</Marker>
      </MapContainer>
      </div>
    </div>
  )
}

export default MyMap
