import React, { useState } from 'react'
import Team from './Team'
import image1 from './workimages/img144.jpg'
import image2 from './workimages/img143.jpg'
import image3 from './workimages/img84.jpg'
import image4 from './workimages/img83.jpg'
import image5 from './workimages/img120.jpg'
import image6 from './workimages/img150.jpg'
import image7 from './workimages/img127.jpg'
import image8 from './workimages/img129.jpg'
import './text.css'
import Question from './Question'
import Download from './Download'
import { Outlet, useNavigate } from 'react-router'
import Content from './Content'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import Navbar from './Navbar'
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Donate from './Donate'


const Service = () => {

  const nav=useNavigate()

function goto(){
  nav('content')
}
function goto1(){
  nav('content1')
}
function goto2(){
  nav('content2')
}
function goto3(){
  nav('content3')
}
function goto4(){
  nav('content4')
}
function goto5(){
  nav('content5')
}

useEffect(()=>{
  Aos.init({   offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,})
},[])

const [record,setrecord]=useState([
  {
    id:1,
    image:image1,
    head:'Botaepa Project: “Nurturing Brighter Futures in Feyiase”',
    message:"In 2017, the Nectar of Hope Foundation embarked on an impactful and compassionate initiative, known as Project Botaepa This heartfelt undertaking was a direct response to the pressing needs of vulnerable children residing in underserved communities, particularly in Bekwai Feyiase."
  },
  {
    id:2,
    image:image2,
    head:"Nectar of Hope for Children's Foundation: Illuminating Futures in Ejisu Krapa",
    message:"Nestled amidst the verdant beauty of Ejisu Krapa, the Nectar of Hope for Children's Foundation (NHCF) embraced the vibrant community with open arms and a shared vision. Moved by an unwavering commitment to sow the seeds of a brighter future, the NHCF recognized the fundamental importance of quality education as the cornerstone of progress."
  },
  {
    id:3,
    image:image3,
    head:'Unveiling Dreams: The Story of Attah Ebenezer',
    message:"In 2017, the foundation had the profound privilege of fulfilling a young boy's cherished dream.  Despite facing physical challenges, a determined 16-year-old boy harbored a deep-seated  aspiration—to meet his sports idol, the iconic Asamoah Gyan, the revered former striker and captain of the Black Stars football team. His fervent desire was to bask in a personal encounter with his athletic hero before embarking on the next chapter of his life's journey."
  },
  {
    id:4,
    image:image5,
    head:'Empowering the youth',
    message:"The Nectar of Hope for Children Foundation, in collaboration with the Kwame Nkrumah University of Science and Technology (KNUST) authorities, played a pivotal role in shaping the destiny of an aspiring young scholar, Seth Agyapong. Embarking on a shared mission to empower dreams, this transformative project marked the beginning of a journey that would profoundly alter the trajectory of a promising life."
  },
  {
    id:5,
    image:image6,
    head:'Restoring Hope: “The Joyce Enyonam Health and Well-being Initiative”',
    message:"In August 2021, an incredibly poignant encounter unfolded as the Nectar of Hope Foundation identified an extraordinary woman on the main campus of Kwame Nkrumah University of Science and Technology (KNUST), Mrs. Joyce Enyonam."
  },
  {
    id:6,
    image:image7,
    head:'Organization of Entertainment and Fun games.”',
    message:"in September 2019, the Nectar of Hope for Children Foundation organized a sensational entertainment event that left an indelible mark on the community. Titled 'Harmony in Motion,' the event was a testament to the foundation's commitment to fostering joy, unity, and creativity. From the meticulous planning to the heartwarming moments shared, this event stands as a shining example of the transformative power of entertainment. The concept of 'Harmony in Motion' was rooted in the idea of bringing people together through various forms of entertainment. "
  },
  {
    id:7,
    image:image8,
    head:'Organization of Tech fairs and competetions”',
    message:"In December 2022,Nectar of Hope for Children Foundation had the incredible opportunity to organize a Tech Fair that brought together technology enthusiasts, industry experts, and aspiring students under one roof. The event aimed not only to showcase the latest advancements in technology but also to foster a culture of innovation and collaboration."
  },
])

const [currentPage,setcurrentPage]=useState(1)
const recordPerPage=3;
const lastIndex=currentPage*recordPerPage;
const firstIndex=lastIndex-recordPerPage;
const main=record.slice(firstIndex,lastIndex)
const npage=Math.ceil(record.length/recordPerPage)
const numbers = [...Array(npage+1).keys()].slice(1)

function nextPage(){
  if(currentPage!==npage){
    setcurrentPage(currentPage+1)
  }
}
function prePage(){
if(currentPage!==1){
  setcurrentPage(currentPage-1)
}
}

function changeCPage(id){
setcurrentPage(id)
}


  return (
    <div className='' >
      <Navbar />
      <div className=' w-full h-[240px] bg-green-600'>
       <div className=' flex justify-center items-center font-bold text-3xl pt-28'>
       <h1 className=' md:text-4xl font-extrabold font-mono text-white'>Campaigns</h1>
       </div>
       <div className=' flex justify-center items-center'>
       <div className=' text-xl font-thin font-serif flex justify-center items-center text-white py-2' >Check out our Campaigns </div>
       </div>
       </div>
       <div className=' bg-white'>
        {
          main.map((data,i)=><div key={i}>
            <div className=' hidden md:block'>
          <div className=' flex justify-center items-center lg:mx-[200px] md:mx-4 mx-[200px]'>
            <div className=' py-5'><img src={data.image} className=' w-[1400px] h-[350px] ' /></div>
            <div className=' px-8 lg:-mt-8 -mt-28  md:mt-14 '>
              <h1 className=' text-3xl font-bold font-serif text-start pb-4'>{data.head}</h1>
              <div className=' text-lg text-start font-thin'>{data.message}
              <div  onClick={()=>{
            if(data.image===image1){
              nav('content6')
            }
            if(data.image==image2){
              nav('content2')
            }
            if(data.image===image3){
              nav('content1')
            }
            if(data.image===image5){
              nav('content')
            }
            if(data.image===image6){
              nav('content3')
            }
            if(data.image===image7){
              nav('content5')
            }
            if(data.image===image8){
              nav('content4')
            }
          }}   className=' cursor-pointer px-6 py-4 '>
            <div className=' flex justify-center items-center w-[120px] h-[50px]  border-2 border-gray-600 text-gray-600 text-lg font-thin '>Read More</div>
        </div>
              </div>
            </div>
          </div>
          <div className=' px-20'>
          <div className=' w-full h-[1px] bg-gray-300'></div></div>
        </div>
        <div className=' md:hidden'>
        <div className=' bg-white' >
        <div className=' flex justify-center items-center'>
          <img src={data.image} className=' px-2 w-[430px] h-[270px] mt-5 pb-2' /></div>
          <div className=' text-start px-3'>
            <h1 className=' text-2xl font-sans font-bold pb-5'>{data.head}</h1>
            <div className=' text-lg text-start font-thin pb-4'>{data.message}</div>
            <div onClick={()=>{
            if(data.image===image1){
              nav('content6')
            }
            if(data.image==image2){
              nav('content2')
            }
            if(data.image===image3){
              nav('content1')
            }
            if(data.image===image5){
              nav('content')
            }
            if(data.image===image6){
              nav('content3')
            }
            if(data.image===image7){
              nav('content5')
            }
            if(data.image===image8){
              nav('content4')
            }
          }} className=' px-6 pb-8 '>
            <div className=' flex justify-center items-center w-[120px] h-[50px]  border-2 border-gray-600 text-gray-600 text-lg font-thin '>Read More</div>
        </div>
          </div>
          <div className=''>
          <div className=' w-full h-[1px] bg-gray-400'></div></div>
       </div>
        </div>
          </div>)
        }
       </div>
       <nav className=' bg-white py-6'>
        <ul className=' flex justify-center items-center'>
          <li className=' w-10 h-10 flex justify-center items-center border-[2px] bg-gray-200 border-black/40 mx-1'>
            <a href='#' onClick={prePage}><LuChevronLeft size={20} /></a>
          </li>
          {
            numbers.map((n,i)=>(
              <li className=' w-10 h-10 flex justify-center items-center border-[2px] bg-gray-200 border-black/40 mx-1' key={i}>
                <a href='#' className={`page-item ${currentPage===n?'w-[150px] h-10 flex justify-center items-center  bg-green-600 text-white ':''}`} onClick={()=>changeCPage(n)}> {n}</a>
              </li>
            ))
          }
          <li className=' w-10 h-10 flex justify-center items-center border-[2px] bg-gray-200 border-black/40 mx-1'>
            <a href='#' onClick={nextPage}><LuChevronRight /></a>
          </li>
        </ul>
       </nav>
       <Donate />
    </div>
  )
}

export default Service
