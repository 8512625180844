import React from 'react'
import { useSpring,animated } from 'react-spring'
import './land.css'
import eductation from './workimages/educaation1.jpg'
import feeding from './workimages/feeding3.png'
import health from './workimages/health3.avif'
import shelter from './workimages/shelter2.avif'
import Lottie from "lottie-react";
import arrow from './down.json'
import { useNavigate } from 'react-router'

const Land = () => {
    function Number({n}){
        const {number} =useSpring({
          from:{number:0},
          number:n,
          delay:200,
          config:{mass:1,tension:20,friction:10},
        });
        return <div><animated.div className=' md:mx-3 lg:text-3xl mx-3 text-2xl' style={{color:'#16a34a',fontFamily:'initial'}}>{number.to((n)=>n.toFixed(0))}</animated.div></div>
      }
const navigate=useNavigate()
function move(){
  navigate('home')
}

  return (
    <div>
        <div className='background'></div>
      <div className='under'></div>
      <div className=' relative text-white text-center md:text-4xl font-sans font-bold text-2xl pt-20 '>Nectar of Hope for Children Foundation
      <div className='  md:hidden px-4 text-start text-white italic text-sm font-normal flex justify-center  items-center '>We make a living by what we get, but we make a life by what we give.</div>
      <div className=' hidden md:block text-white text-sm italic font-normal justify-center items-center '>Welcome to a place where compassion meets action, where each donation sparks a wave of change,and where every volunteer becomes a beacon of hope.</div>
       <div className=' hidden md:block  text-white text-sm italic font-normal justify-center items-center '> At Nector Of Hope for Children Foundation, we're not just a charity; we are a movement a force for good that's changing the world one heart, one community at a time.</div>
      <div className=' mt-8'>
      <h1 className=' text-3xl font-semibold font-sans py-4'>Strategic Focus</h1>
        <div className=' flex justify-center items-center' >
            <div className=' lg:mx-20 md:mx-10 mx-2'><img src={feeding} className=' md:w-[100px] md:h-[100px] w-[60px] h-[60px] rounded-full' />
            <div className=' text-xl font-thin'>Feeding</div>
            </div>
            <div className=' lg:mx-20 md:mx-10 mx-2'><img src={eductation} className=' md:ml-0 ml-3 md:w-[100px] md:h-[100px] w-[60px] h-[60px] rounded-full'  />
            <div className=' text-xl font-thin'>Education</div>
            </div>
            <div className=' lg:mx-20 md:mx-10 mx-2'><img src={health} className=' md:w-[100px] md:h-[100px] w-[60px] h-[60px] rounded-full'  />
            <div className=' text-xl font-thin'>Health</div>
            </div>
            <div className=' lg:mx-20 md:mx-10 mx-2'><img src={shelter} className=' md:w-[100px] md:h-[100px] w-[60px] h-[60px] rounded-full'  />
            <div className=' text-xl font-thin '>Shelter</div>
            </div>
        </div>
      </div>
      <div className=' text-3xl font-serif font-semibold md:py-4 md:mt-14 mt-10'>Projects</div>
        <div className='text md:mt-[1px] '>
    <div className='inline-block md:mx-10 md:text-lg text-sm font-semibold mx-4'>
       <div className=' inline-block'><Number n={700}/></div>  
       <div className=' inline-block top-44 text-3xl -mx-3' style={{color:'#16a34a'}}>+</div>
        <div className=' ml-4'>Feeding</div>
      </div>
      <div className='inline-block md:mx-10 md:text-lg text-sm font-semibold mx-4'>
      <div className=' inline-block'><Number n={120}/></div>  
      <div className=' inline-block top-44 text-3xl -mx-3' style={{color:'#16a34a'}}>+</div>
        <div>Education</div>
      </div>
      <div className='inline-block md:mx-10 md:text-lg text-sm font-semibold mx-4'>
      <div className=' inline-block'><Number n={78}/></div>  
      <div className=' inline-block top-44 text-3xl -mx-3' style={{color:'#16a34a'}}>+</div>
        <div className=' ml-2'>Health</div>
      </div>
      <div className='inline-block md:mx-10 md:text-lg text-sm font-semibold mx-4'>
      <div className=' inline-block'><Number n={51}/></div>  
      <div className=' inline-block top-44 text-3xl -mx-3' style={{color:'#16a34a'}}>+</div>
        <div>Shelter</div>
      </div>
      </div>
      <div className=' flex justify-center items-center '>
      <div><Lottie animationData={arrow} style={{width:'70px',fontWeight:'bold'}} /></div>
      </div>
      <div className=' flex justify-center items-center cursor-pointer' onClick={move}>
      <div className=' w-[120px] h-[40px] text-center text-xl font-thin flex justify-center items-center rounded-lg shadow-md' style={{backgroundColor:'#16a34a'}}>Explore</div>
      </div>
      </div>
    </div>
  )
}

export default Land
