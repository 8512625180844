import React, { useEffect, useState } from 'react'
import image1 from './workimages/img113.jpg'
import Loader from './Loader'
import img1 from './workimages/img127.jpg'
import { PiArrowCircleLeftFill } from "react-icons/pi";
import { useNavigate } from 'react-router';
import Navbar from './Navbar';
import Donate from './Donate';

const Content5 = () => {

const [loading, setloading]=useState(true)
useEffect(()=>{
  setTimeout(() => {
    setloading(false)
  }, 4000);
})

const nav=useNavigate()
function back(){
nav(-1)
}

  return (
    <div>
      {loading==true?<div className='yeah w-full h-full bg-white'><Loader /></div>:
      <div><Navbar />
      <div className='bg-white flex justify-center items-center md:px-64 px-3'>
      <div  className=' text-xl  text-black text-justify'>
      <div className='  w-full h-[40px] bg-green-600 mb-2 py-32  flex justify-center items-center' style={{width:'100%'}}>
          <div className=' inline-block '><h1 className='md:text-3xl text-2xl font-bold text-center  text-white md:inline-block md:pt-10 pt-16'  >Organization of Entertainment And fun games</h1></div></div>
      <div className=' flex justify-center items-center'> <img src={img1} className=' md:h-[800px] h-[460px]' style={{width:'100%'}} /></div>
<div className=' py-5 text-xl text-gray-700 font-thin font-serif mb-4'>
Two years ago, the Nectar of Hope for Children Foundation organized a sensational entertainment event that left an indelible mark on the community. Titled "Harmony in Motion," the event was a testament to the foundation's commitment to fostering joy, unity, and creativity. From the meticulous planning to the heartwarming moments shared, this event stands as a shining example of the transformative power of entertainment.
The concept of "Harmony in Motion" was rooted in the idea of bringing people together through various forms of entertainment. The foundation aimed to create an inclusive space where individuals from diverse backgrounds could come together, celebrate, and share in the joy of artistic expression. Months of planning went into crafting a program that would resonate with the community and showcase a rich tapestry of talents.
<div className=' py-5'>
The event featured a dazzling array of performances, ranging from vibrant dance routines and soul-stirring musical acts to thought-provoking theatrical performances. Local talents, as well as renowned artists, graced the stage, creating an electrifying atmosphere that captivated the audience. The diversity of the performances reflected the rich cultural tapestry of the community and resonated with attendees of all ages.
"Harmony in Motion" went beyond being a mere entertainment spectacle; it was a celebration of community spirit. Local businesses, schools, and artists collaborated to make the event a success. Workshops, art installations, and interactive activities added layers of engagement, ensuring that attendees could not only witness but actively participate in the festivities. The event fostered a sense of community pride and collaboration.
<div className=' py-5'>In line with the foundation's mission, "Harmony in Motion" also served as a platform to raise awareness and funds for charitable causes. Booths and exhibits were set up to showcase the foundation's ongoing projects, and donations were collected to support various community initiatives. The seamless integration of entertainment and philanthropy underscored the foundation's commitment to making a positive impact on society.
The success of "Harmony in Motion" lay in the unforgettable moments it created. From the cheers of the audience during awe-inspiring performances to the quiet moments of reflection at the charity exhibits, the event left a lasting impression on everyone involved. Families, friends, and neighbors came together, forging bonds that extended beyond the event itself.
</div>
<div className=' py-5'>"Harmony in Motion" was not just an entertainment event; it was a celebration of community, culture, and compassion. The foundation's commitment to organizing an event that transcended the boundaries of entertainment and community engagement resulted in an unforgettable experience for all. As we reflect on this remarkable event two years later, its impact remains a beacon of inspiration for the foundation's ongoing efforts to bring joy, unity, and positive change to the community.</div>
</div>
</div>
      </div>
      </div>
      <Donate />
        </div>}
    </div>
  )
}

export default Content5
