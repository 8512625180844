import React, { useEffect, useState } from 'react'
import image1 from './workimages/img113.jpg'
import Loader from './Loader'
import img1 from './workimages/img121.jpg'
import { PiArrowCircleLeftFill } from "react-icons/pi";
import { Outlet, useNavigate } from 'react-router';
import Navbar from './Navbar';
import Donate from './Donate';
const Content = () => {

const [loading, setloading]=useState(true)
useEffect(()=>{
  setTimeout(() => {
    setloading(false)
  }, 4000);
})

const nav=useNavigate()
function back(){
nav(-1)
}

  return (
    <div>
      {loading==true?<div className='yeah w-full h-full bg-white'><Loader /></div>:
      <div><Navbar />
      <div className='bg-white flex justify-center items-center md:px-64 px-3'>
      <div  className=' text-xl  text-black text-justify'>
        <div className='  w-full h-[40px] bg-green-600 mb-2 py-32  flex justify-center items-center' style={{width:'100%'}}>
          <div className=' inline-block '><h1 className='md:text-3xl text-2xl font-bold text-center  text-white md:inline-block md:pt-10 pt-16'  >Empowering the youth</h1></div></div>
      <div className=' flex justify-center items-center'> <img src={img1} className=' md:h-[800px] h-[460px]' style={{width:'100%'}} /></div>
<div className=' py-5 text-xl text-gray-700 font-thin font-serif mb-4'>
The Nectar of Hope for Children Foundation, in 
collaboration with the Kwame Nkrumah University of 
Science and Technology (KNUST) authorities, played a 
pivotal role in shaping the destiny of an aspiring young 
scholar, Seth Agyapong. Embarking on a shared mission to 
empower dreams, this transformative project marked the 
beginning of a journey that would profoundly alter the 
trajectory of a promising life.
<div className=' py-5'>
Within the framework of this visionary initiative, Seth Agyapong ceased to be just a 
student; he became a symbol of unwavering determination and limitless potential. 
Equipped with a full scholarship and unwavering support, he commenced his 
undergraduate education, inching closer to the realization of his long-cherished 
aspirations. The seamless partnership between the foundation and the university not only 
lifted financial burdens but also served as a beacon of hope, ensuring that Seth's academic 
pursuits were unimpeded.
<div className=' py-5'>Seth Agyapong originally hails from a small community where NHCF had previously 
implemented various community projects. During interactions with our team, it came to 
light that Seth was a student at the esteemed Kwame Nkrumah University of Science and 
Technology, KNUST. He candidly shared the numerous challenges he encountered on 
campus while striving to make ends meet. Seth's determination led him to engage in 
street hawking and running errands for his fellow students, all in an effort to support 
himself. Despite hailing from a less privileged background, his ardent thirst for 
knowledge propelled us to extend a helping hand, ensuring a conducive environment for 
his academic endeavors.</div>
<div className=' py-5'>The foundation advocated on behalf of Seth to the school administration, ultimately 
securing a student scholarship and covering his accommodation expenses. Today, owing 
to his resilience and the grace of providence, Seth has successfully graduated from the 
university with an impressive academic record.</div>
</div>
</div>
      </div>
      </div>
      <Donate />
        </div>}
    </div>
  )
}

export default Content
