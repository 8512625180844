import React, { useEffect, useState } from 'react'
import image1 from './workimages/img113.jpg'
import Loader from './Loader'
import img1 from './workimages/img150.jpg'
import { PiArrowCircleLeftFill } from "react-icons/pi";
import { useNavigate } from 'react-router';
import Navbar from './Navbar';
import Donate from './Donate';

const Content3 = () => {

const [loading, setloading]=useState(true)
useEffect(()=>{
  setTimeout(() => {
    setloading(false)
  }, 4000);
})

const nav=useNavigate()
function back(){
nav(-1)
}

  return (
    <div>
      {loading==true?<div className='yeah w-full h-full bg-white'><Loader /></div>:
      <div><Navbar />
      <div className='bg-white flex justify-center items-center md:px-64 px-3'>
      <div  className=' text-xl  text-black text-justify'>
      <div className='  w-full h-[40px] bg-green-600 mb-2 py-32  flex justify-center items-center' style={{width:'100%'}}>
          <div className=' inline-block '><h1 className='md:text-3xl text-2xl font-bold text-center  text-white md:inline-block md:pt-10 pt-16'  >Restoring Hope: “The Joyce Enyonam Health and Well-being Initiative”</h1></div></div>
      <div className=' flex justify-center items-center'> <img src={img1} className=' md:h-[800px] h-[460px]' style={{width:'100%'}} /></div>
<div className=' py-5 text-xl text-gray-700 font-thin font-serif mb-4'>
In August 2021, an incredibly poignant encounter unfolded as the Nectar of Hope Foundation 
identified an extraordinary woman on the main campus of Kwame Nkrumah University of 
Science and Technology (KNUST), Mrs. Joyce Enyonam. Her life had been a testament to 
resilience, as she courageously confronted a debilitating health condition that had persisted for 
over two decades. Mrs. Joyce shared with the foundation the challenging journey of living with 
goiter, a condition that had significantly impacted her quality of life for more than 20 years.
<div className=' py-5'>
Beyond her personal resilience, Mrs. Joyce's story epitomized extraordinary strength, as she 
shouldered the responsibilities of her family following the tragic loss of her husband five years 
prior. Her unwavering determination to provide for her family was evident through her
enterprising efforts, selling plantain chips, "Atwemo," and donuts, which had become a source of 
sustenance for many on the campus, particularly during demanding examination periods.
<div className=' py-5'>Despite her remarkable perseverance, her health struggles cast a shadow over her daily life, with 
goiter posing significant challenges to her breathing and sleep. Responding swiftly, the 
foundation, under the leadership of Mr. Thomas Tawiah Baah, mobilized support to address Mrs. 
Joyce's pressing medical needs. By January 2023, the foundation had successfully raised a 
substantial sum to facilitate the much-needed medical procedures. As part of the medical 
assessment, Mrs. Joyce underwent a comprehensive series of tests, scans, and specialist 
consultations at the KNUST Hospital, Peace and Love Clinic in Oduom, and the Komfo Anokye 
Teaching Hospital in Kumasi, Ashanti Region. We aim to support her in undergoing a successful 
surgery in the upcoming days. We earnestly appeal to the general public to join us in making 
this significant transformation in her life possible.</div>
<div className=' py-5'>Restoring Hope: The Joyce Enyonam Health and Well-being Initiative" serves as a profound 
embodiment of our foundation's core mission — to empower lives and instill hope in those who 
need it most. This transformative project serves as a beacon of collective action, resilience, and 
compassion, underscoring the profound truth that no challenge is insurmountable when a 
community unites in purpose. It symbolizes our steadfast belief in the potential for 
transformative change and the enduring resilience of the human spirit</div>
</div>
</div>
      </div>
      </div>
      <Donate />
        </div>}
    </div>
  )
}

export default Content3
