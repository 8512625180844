import React from 'react'
import logo from './workimages/WhatsApp Image 2023-10-23 at 4.46.17 PM.jpeg'
import './text.css';
import work1 from './workimages/img113.jpg'
import work2 from './workimages/img171.jpg'
import work3 from './workimages/img170.jpg'
import work4 from './workimages/img145.jpg'
import work5 from './workimages/img171.jpg'
import Carousel from './Carousel';
import Service from './Service';
import Team from './Team';
import Question from './Question';
import Download from './Download';
import Navbar from './Navbar';
import Home from './Home';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import Footer from './Footer';
import Donate from './Donate';

const About = () => {

  useEffect(()=>{
    Aos.init({   offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,})
  },[])

  return (
    <div >  
      <Navbar />
    <div className='' name='yes'>
      <div className=' w-full h-[240px] bg-green-600'>
       <div className=' flex justify-center items-center font-bold text-3xl pt-28'>
       <h1 className=' md:text-4xl font-extrabold font-mono text-white'>About Us</h1>
       </div>
       <div className=' flex justify-center items-center'>
       <div className=' text-xl font-thin font-serif flex justify-center items-center text-white py-2' >Discover more about Our Story </div>
       </div>
       </div>
       <div className=" bg-white">
        <div className=' text-3xl font-bold font-serif py-8'>Our Mission And Vision</div>
          <div className=" flex justify-center items-center">
            <div className=" grid md:grid-cols-2 gap-10 md:mx-14 py-[10px] pb-24">
              <div className=" bg-green-50">
                <div className=" text-2xl font-mono py-4">vision</div>
                <div className=" text-lg font-sans pb-4 px-5">Nectar of Hope Foundation envisions a world where every child and individual regardless of 
their circumstances, has access to quality education, healthcare, shelter and nutritious food fostering a society where the Sustainable Development Goals (SDG’s) are realized and potential 
flourishes</div>
              </div>
              <div className=" bg-green-50">
                <div className=" text-2xl font-mono py-4">Mission</div>
                <div className=" text-lg font-sans pb-4 px-5">Nectar of Hope Foundation is dedicated to providing underprivileged children and communities 
with holistic support breaking the cycle of poverty and advancing the SDG’s for a better world. 
Our mission is to be the catalyst for positive change, nurturing hope and building a better 
brighter future for all.</div>
              </div>
            </div>
            </div>
          </div>
          <div className=" bg-black/40 md:pb-20 pb-0">
    <div className=" flex justify-center items-center">
    <img src={logo} className=" w-28 h-28 rounded-full mt-10" />
    </div>
    <div className=" text-2xl font-san font-extrabold text-white pb-2">Concept of Nector Of Hope For Children Foundation</div>
    <div className=' text-lg font-mono font-thin text-white pb-10'>The story of Nectar of Hope For Children Foundation</div>
    <div>
    <div className=" flex justify-center items-center">
      <div className=" md:grid md:grid-cols-2 gap-5">
        <div className=" bg-white md:ml-20 md:mb-0 mb-5 md:mx-0 mx-6 pb-10  rounded-lg" data-aos='zoom-in'>
<div className=" text-lg font-sans md:py-6 py-2 px-5">Nectar of Hope for Children Foundation (NHCF), Ghana, stands as a beacon of compassion and 
resilience, striving tirelessly to bridge the gaps that hinder the holistic development of 
underprivileged children.<div className='hidden md:block'>Our mission, firmly rooted in the belief that every child deserves a fair 
chance at a prosperous future, is to provide not just the basic necessities of life, but also to 
cultivate an environment where these young minds can thrive and reach their full potential. 
NHCF's unwavering commitment to quality education, accessible healthcare, secure shelter, and 
nutritious food acts as the cornerstone of our efforts.</div>  We understand that these fundamental 
elements lay the foundation for a brighter tomorrow, and it is our aim to ensure that no child is 
deprived of these essential building blocks.</div>
        </div>
        <div className=" bg-white md:mr-20 md:mb-0 mb-5 md:mx-0 mx-6 rounded-lg" data-aos='zoom-in'>
<div className=" text-lg font-sans py-12 px-5">The genesis of NHCF dates back to 2016, when a group of compassionate and dedicated students 
from the Kwame Nkrumah University of Science and Technology (KNUST), led by the 
visionary Abdul Rahman, recognized the pressing need to address the educational and social 
disparities affecting the children of Ghana. Witnessing the struggles and hurdles faced by these 
young souls, the team galvanized their efforts and channeled their passion into the formation of a 
charitable, non-profit organization that would serve as a catalyst for change..</div>
        </div>
        <div className=" bg-white md:ml-20 md:mb-0 mb-5 md:mx-0 mx-6 rounded-lg pb-4 hidden md:block" data-aos='zoom-in'>
<div className=" text-lg font-sans pb-4 py-8 px-5">Since our inception, NHCF has stood as a pillar of hope and resilience, working tirelessly to 
transform the destinies of countless children and their families across various communities, 
particularly in the remote rural areas where resources are scarce. Our dedicated team, fueled by a 
shared vision of creating a more equitable society, has implemented a range of initiatives and 
programs that have not only touched the lives of these children but have also left a lasting impact 
on the communities we serve. However, our journey has not been without its share of challenges. <div className=' hidden md:block'>Yet, through perseverance and the unwavering support of our partners, volunteers, and 
stakeholders, we continue to push the boundaries of what is possible, striving to break down 
barriers and create a world where every child has the opportunity to dream and achieve.</div>
</div>
        </div>
        <div className=" bg-white md:mr-20 md:mb-0 mb-5 md:mx-0 mx-6 rounded-lg pb-4" data-aos='zoom-in'>
<div className=" text-lg font-sans pb-4 py-8 px-5">As we look towards the future, NHCF remains resolute in its commitment to fostering 
sustainable change and building a brighter, more inclusive tomorrow for the children of Ghana. 
With the support of our dedicated team and the generosity of our well-wishers, we are confident 
that our collective efforts will continue to pave the way for a more promising and prosperous future for the children we serve. Together, we aspire to be the catalyst for transformation, the 
guiding light that illuminates the path to a better tomorrow for every child in Ghana.</div>
        </div>
      </div>
    </div>
    </div>
  </div>
        </div>
        <Carousel />
        <Donate />
    </div>
  )
}

export default About
