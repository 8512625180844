import React, { useEffect, useState } from 'react'
import image1 from './workimages/img113.jpg'
import Loader from './Loader'
import img1 from './workimages/img144.jpg'
import { PiArrowCircleLeftFill } from "react-icons/pi";
import { useNavigate } from 'react-router';
import Navbar from './Navbar';
import Donate from './Donate';


const Content6 = () => {

const [loading, setloading]=useState(true)
useEffect(()=>{
  setTimeout(() => {
    setloading(false)
  }, 4000);
})

const nav=useNavigate()
function back(){
nav(-1)
}

  return (
    <div>
      {loading==true?<div className='yeah w-full h-full bg-white'><Loader /></div>:
      <div><Navbar />
      <div className='bg-white flex justify-center items-center md:px-64 px-3'>
      <div  className=' text-xl  text-black text-justify'>
      <div className='  w-full h-[40px] bg-green-600 mb-2 py-32  flex justify-center items-center' style={{width:'100%'}}>
          <div className=' inline-block '><h1 className='md:text-3xl text-2xl font-bold text-center  text-white md:inline-block md:pt-10 pt-16'  >Botaepa Project: “Nurturing Brighter Futures in Feyiase”</h1></div></div>
      <div className=' flex justify-center items-center'> <img src={img1} className=' md:h-[800px] h-[460px]' style={{width:'100%'}} /></div>
<div className=' py-5 text-xl text-gray-700 font-thin font-serif mb-4'>
In 2017, the Nectar of Hope Foundation embarked on an impactful and compassionate initiative, 
known as "Project Botaepa." This heartfelt undertaking was a direct response to the pressing 
needs of vulnerable children residing in underserved communities, particularly in Bekwai 
Feyiase.
<div className=' py-5'>
The primary objective of this noble project was to extend a helping hand to those in need, 
offering essential provisions that would positively impact the lives of these young individuals. 
"Project Botaepa" adopted a comprehensive approach, addressing crucial aspects of well-being 
such as access to nourishment, clothing, and educational materials, laying the foundation for a 
more promising and sustainable future.
<div className=' py-5'>Through its unwavering commitment to instilling hope and resilience, this project served as a 
poignant manifestation of the belief that with the right support and resources, children can 
triumph over adversity and strive for a better tomorrow. "Project Botaepa: Nurturing Brighter 
Futures in Feyiase" stands as a testament to the unwavering dedication of our foundation in 
fostering positive change and cultivating a sense of possibility, even in the face of the most 
daunting challenges. It symbolizes our steadfast belief in the transformative power of collective 
action and the remarkable potential for change when communities come together in unity and 
compassion</div>
</div>
</div>
      </div>
      </div>
      <Donate />
        </div>}
    </div>
  )
}

export default Content6
