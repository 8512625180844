import React from "react";
import {FaLinkedin} from 'react-icons/fa6'
import {FaSquareInstagram} from 'react-icons/fa6'
import {AiFillFacebook} from 'react-icons/ai'
import {AiFillTwitterSquare} from 'react-icons/ai'
import {BsFillArrowUpSquareFill} from 'react-icons/bs'
import {BiCopyright} from 'react-icons/bi'
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const Footer = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div className=" md:mt-0 mt-[0px] md:py-4 py-20 bg-gray-50" >
      <div className=" grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4">
        <div>
          <h1 className=" text-xl font-bold text-black-600 opacity-70 mb-3">
            Awards
          </h1>
          <div className="text-black/70">
            <ul className=" py-1">NUGS Humanitarian Award-2017</ul>
            <ul className=" py-1">
              Certificate of Honor from KNUST(Department of social work)
            </ul>
            <ul className=" py-1">Citations from students</ul>
          </div>
        </div>
        <div>
          <h1 className=" text-xl font-bold text-black-600 opacity-70 mb-3">
            Affilates
          </h1>
          <div className="text-black/70">
            <ul className=" py-1">
              Kwame Nkrumah University Of Science And Technology(KNUST)
            </ul>
            <ul className=" py-1">National Union of Ghana Students</ul>
            <ul className=" py-1">KNUST Student Representative Council</ul>
            <ul className=" py-1">Graduate Student Associations(GRASAG)</ul>
          </div>
        </div>
        <div>
          <h1 className=" text-xl font-bold text-black-600 opacity-70 mb-3">
            contact Info
          </h1>
          <div className="text-black/70">
            <ul className=" py-1">nhcfghoffice@gmail.com</ul>
            <ul className=" py-1">contact:+233 50 472 3390</ul>
          </div>
        </div>
      </div>
      <div className=" py-2 bg-gray-50" >
        <div className=" w-full border-[1px] border-gray-400 text-black"></div>
        <div className=" grid grid-cols-2">
          <div>
            <h1 className="text-lg font-bold text-black-600 opacity-70">Get intouch with Us</h1>
            <div className=" flex justify-center items-center text-4xl py-1 text-green-600" >
            <a href="https://www.instagram.com/nhcf_gh/?hl=en" ><FaSquareInstagram /> </a>
            <a href="https://www.facebook.com/NHCFGhana/" ><AiFillFacebook /></a>
            <a href="https://twitter.com/nhcf_gh?lang=en" ><AiFillTwitterSquare /></a>
            </div>
          </div>
          <div className=" flex justify-center items-center py-2 text-5xl text-green-600">
            <div onClick={scrollToTop}><BsFillArrowUpSquareFill /></div>
          </div>
        </div>
        <div className=" flex justify-center items-center">
          <div><BiCopyright size={25} /></div>
          <div>Copyright 2023.-Built by <a className=" text-green-600 font-bold" href="mailto:prince.kojonyame@gmail.com">Prince Nyame</a></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
