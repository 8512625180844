import 'firebase/app'
import { initializeApp } from 'firebase/app'

import 'firebase/storage'
import { getStorage } from 'firebase/storage'

const firebaseConfig=initializeApp({
    apiKey: "AIzaSyDJ8drTXFxBZ9cf19-hY1ZKBHMpUOp0kI8",

  authDomain: "nhcf-1358e.firebaseapp.com",

  projectId: "nhcf-1358e",

  storageBucket: "nhcf-1358e.appspot.com",

  messagingSenderId: "792117220488",

  appId: "1:792117220488:web:9f052dcd1235aff946bb3d",

  measurementId: "G-CWRDS1NN1J"

})

const storage=getStorage(firebaseConfig)

export default storage