import React, { useEffect, useState } from 'react'
import image1 from './workimages/img113.jpg'
import Loader from './Loader'
import img1 from './workimages/img143.jpg'
import { PiArrowCircleLeftFill } from "react-icons/pi";
import { useNavigate } from 'react-router';
import Navbar from './Navbar';
import Donate from './Donate';

const Content2 = () => {

const [loading, setloading]=useState(true)
useEffect(()=>{
  setTimeout(() => {
    setloading(false)
  }, 4000);
})

const nav=useNavigate()
function back(){
nav(-1)
}

  return (
    <div>
      {loading==true?<div className='yeah w-full h-full bg-white'><Loader /></div>:
      <div><Navbar />
      <div className='bg-white flex justify-center items-center md:px-64 px-3'>
      <div  className=' text-xl  text-black text-justify'>
      <div className='  w-full h-[40px] bg-green-600 mb-2 py-32  flex justify-center items-center' style={{width:'100%'}}>
          <div className=' inline-block '><h1 className='md:text-3xl text-2xl font-bold text-center  text-white md:inline-block md:pt-10 pt-16'  >Nectar of Hope for Children's Foundation: Illuminating Futures in Ejisu Krapa</h1></div></div>
      <div className=' flex justify-center items-center'> <img src={img1} className=' md:h-[800px] h-[460px]' style={{width:'100%'}} /></div>
<div className=' py-5 text-xl text-gray-700 font-thin font-serif mb-4'>
Nestled amidst the verdant beauty of Ejisu Krapa, the Nectar of Hope for Children's 
Foundation (NHCF) embraced the vibrant community with open arms and a shared vision. 
Moved by an unwavering commitment to sow the seeds of a brighter future, the NHCF 
recognized the fundamental importance of quality education as the cornerstone of progress. With 
a deep-seated belief in the transformative power of knowledge, the NHCF, guided by their 
indomitable spirit, resolved to ignite a beacon of hope in the hearts of the children of Ejisu 
Krapa.
<div className=' py-5'>
Their donation to the local basic school stood as a testament to their unwavering 
dedication to fostering an environment where every child could blossom and thrive, regardless of 
their circumstances. The echoes of the NHCF's benevolence reverberated through the corridors 
of the basic school in Ejisu Krapa, infusing the atmosphere with an aura of newfound possibility 
and promise. Through their timely and generous donation, the NHCF catalyzed a transformative 
wave of change, enriching the educational landscape and igniting a spark of hope within the 
hearts of the young learners.
<div className=' py-5'>The infusion of essential resources breathed new life into the 
school, facilitating the acquisition of vital educational materials, the enhancement of 
infrastructure, and the implementation of innovative teaching methodologies. As the school 
blossomed into a nurturing haven for knowledge-seekers, the children of Ejisu Krapa found 
themselves empowered, their horizons broadened, and their dreams emboldened by the 
unyielding support of the NHCF.</div>
<div className=' py-5'>With each lesson imparted and every aspiration kindled, the 
NHCF's contribution illuminated the path toward a future where the children of Ejisu Krapa 
could dare to dream, aspire, and achieve beyond the confines of their past</div>
</div>
</div>
      </div>
      </div>
      <Donate />
        </div>}
    </div>
  )
}

export default Content2
