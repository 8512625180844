import React, { useEffect, useState } from 'react'
import image1 from './workimages/img113.jpg'
import Loader from './Loader'
import img1 from './workimages/img129.jpg'
import { PiArrowCircleLeftFill } from "react-icons/pi";
import { useNavigate } from 'react-router';
import Navbar from './Navbar';
import Donate from './Donate';

const Content4 = () => {

const [loading, setloading]=useState(true)
useEffect(()=>{
  setTimeout(() => {
    setloading(false)
  }, 4000);
})

const nav=useNavigate()
function back(){
nav(-1)
}

  return (
    <div>
      {loading==true?<div className='yeah w-full h-full bg-white'><Loader /></div>:
      <div><Navbar />
      <div className='bg-white flex justify-center items-center md:px-64 px-3'>
      <div  className=' text-xl  text-black text-justify'>
      <div className='  w-full h-[40px] bg-green-600 mb-2 py-32  flex justify-center items-center' style={{width:'100%'}}>
          <div className=' inline-block '><h1 className='md:text-3xl text-2xl font-bold text-center  text-white md:inline-block md:pt-10 pt-16'  >Organization of tech Fair And competetions”</h1></div></div>
      <div className=' flex justify-center items-center'> <img src={img1} className=' md:h-[800px] h-[460px]' style={{width:'100%'}} /></div>
<div className=' py-5 text-xl text-gray-700 font-thin font-serif mb-4'>
In December 2022,Nectar of Hope for Children Foundation had the incredible opportunity to organize a Tech Fair that brought together technology enthusiasts, industry experts, and aspiring students under one roof. The event aimed not only to showcase the latest advancements in technology but also to foster a culture of innovation and collaboration. The Tech Fair 2022 was a resounding success, leaving an indelible mark on the tech landscape.
From securing a venue equipped with cutting-edge facilities to inviting top-notch speakers and tech companies, every detail was carefully considered. The theme, "Innovate. Collaborate. Transform," served as the guiding principle, emphasizing the dynamic nature of the tech industry.
<div className=' py-5'>
The heart of the Tech Fair was the exhibition area, where a diverse array of companies and startups showcased their innovations. Attendees had the opportunity to interact with state-of-the-art gadgets, witness live demonstrations, and engage in discussions with tech experts. From augmented reality experiences to groundbreaking advancements in artificial intelligence, the exhibition hall was a testament to the rapid evolution of technology.
One of the most rewarding aspects of the Tech Fair was the active participation of students. We provided a platform for young minds to present their projects, prototypes, and research findings. The Student Innovation Zone buzzed with energy as students demonstrated their creativity and problem-solving skills. The fair not only celebrated current achievements but also inspired the next generation of tech leaders.
<div className=' py-5'>To complement the exhibition, the Tech Fair featured a series of workshops and seminars conducted by industry leaders. Topics ranged from the ethical implications of emerging technologies to hands-on coding sessions. Attendees had the opportunity to deepen their understanding of key technological trends and gain insights into the future of the industry.
The Tech Fair served as a hub for networking, connecting professionals, students, and entrepreneurs. The collaborative atmosphere facilitated meaningful conversations and potential collaborations. Attendees seized the chance to exchange ideas, explore potential partnerships, and expand their professional networks.
</div>
<div className=' py-5'>The Tech Fair 2022 was not merely an event; it was a celebration of innovation, collaboration, and the transformative power of technology. The resounding success of the fair underscored the importance of creating spaces where ideas could flourish, and individuals could be inspired to push the boundaries of what is possible. As we look back on the event, it is clear that the Tech Fair played a pivotal role in shaping the tech landscape and fostering a community committed to driving positive change through technology.</div>
</div>
</div>
      </div>
      </div>
      <Donate />
        </div>}
    </div>
  )
}

export default Content4
