import React from 'react'
import Footer from './Footer'

const Donate = () => {
  return (
    <div>
      <div className=' relative md:top-0 top-[0px] md:pb-0 md:py-0 py-4 bg-white'>
        <div className=' flex justify-start items-start px-14 text-2xl text-green-600 font-medium py-7'>Support Us</div>
        <div className=' flex justify-start items-start md:px-10 md:text-3xl font-semibold font-serif text-2xl'>Donate to support our vision </div>
        <div className=' flex justify-start items-start text-lg font-serif md:px-10 py-2 text-justify px-3'>We aim to continue to invest in Ghana’s youth by connecting them with opportunities to transform their lives and communities.</div>
        <div className=' px-10 pb-10 py-5'>
            <a href="tel:+233 50 472 3390" className=' flex justify-center items-center w-[170px] h-[70px] rounded-full border-4 border-green-600 text-green-600 text-xl font-bold '>Donate Now</a>
        </div>
      </div>
      <div><Footer /></div>
    </div>
  )
}

export default Donate
