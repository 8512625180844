import React from 'react'
import {SlLocationPin} from 'react-icons/sl'
import {TfiEmail} from 'react-icons/tfi'
import {FiPhoneCall} from 'react-icons/fi'
import MyMap from './MyMap'
import './input.css'
import './send.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Donate from './Donate'

const Contact = () => {
  useEffect(()=>{
    Aos.init({   offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,})
  },[])
  return (
    <div  >
      <Navbar />
      <div className=' w-full h-[240px] bg-green-600'>
       <div className=' flex justify-center items-center font-bold text-3xl pt-28'>
       <h1 className=' md:text-4xl font-extrabold font-mono text-white'>Contact Us</h1>
       </div>
       <div className=' flex justify-center items-center'>
       <div className=' text-xl font-thin font-serif flex justify-center items-center text-white py-2' >Get intouch with us.We will respond as soon as possible </div>
       </div>
       </div>
      <div className=' hidden md:block bg-black/40 pt-8'>
        <div className=' md:grid md:grid-cols-3 gap-2 px-2 hidden'>
          <div data-aos='fade-up' className=' lg:w-[400px]  md:w-[250px] w-[380px] md:mb-0 mb-4 h-[200px] border-4 border-white py-6 drop-shadow-2xl bg-white md:mx-10  '>
            <div className=' flex justify-center items-center'>
              <div className=' w-16 h-16 border-2 border-green-600 rounded-full text-center flex justify-center items-center text-[40px] text-green-700'><SlLocationPin /></div>
              </div>
            <div className=' font-bold text-xl text-gray-700 opacity-75 py-2'>Our Address</div>
            <div>Kwame Nkrumah University of Science and Tech</div>
          </div>
          <div data-aos='fade-up' className=' lg:w-[350px]  md:w-[200px] w-[380px] md:mb-0 mb-4 h-[200px] border-4 border-white py-6 drop-shadow-2xl bg-white md:mx-14  '>
            <div className=' flex justify-center items-center'>
              <div className=' w-16 h-16 border-2 border-green-600 rounded-full text-center flex justify-center items-center text-[40px] text-green-700'><TfiEmail /></div>
              </div>
              <div className=' font-bold text-xl text-gray-700 opacity-75 py-2'>Email Us</div>
            <div> nhcfghoffice@gmail.com</div>
          </div>
          <div data-aos='fade-up' className=' lg:w-[320px] md:w-[230px] w-[380px] md:mb-0 mb-4 h-[200px] border-4 border-white py-6 drop-shadow-2xl bg-white mx-6'>
            <div className=' flex justify-center items-center'>
              <div className=' w-16 h-16 border-2 border-green-600 rounded-full text-center flex justify-center items-center text-[40px] text-green-700'><FiPhoneCall /></div>
              </div>
              <div className=' font-bold text-xl text-gray-700 opacity-75 py-2'>Call Us</div>
              <div>+233 50 472 3390</div>
          </div>
        </div>
      </div>
      <div className=' hidden md:block bg-black/40'>
      <div className=' md:grid md:grid-cols-2 py-10'>
      <MyMap />
      <div className=' flex justify-center items-center'>
      <div className=' lg:w-[460px] md:w-[420px] w-[380px] h-[70vh] bg-white border-4 md:my-0 my-10 drop-shadow-2xl lg:mr-64 mr-10'>
        <div className='grid grid-cols-2 gap-2 py-5'>
          <div>
          <div class="input-container">
  <input placeholder="Your Name" class="input-field" type="text" />
  <label for="input-field" class="input-label">Your Name</label>
  <span class="input-highlight"></span>
</div>
          </div>
 <div>
 <div class="input-container">
  <input placeholder="Your Email" class="input-field" type="text" />
  <label for="Your Email" class="input-label">Your Email</label>
  <span class="input-highlight"></span>
</div>
 </div>
        </div>
        <div>
 <div class="input-container">
  <input placeholder="Subject" class="input-field" type="text" />
  <label for="input-field" class="input-label">Subject</label>
  <span class="input-highlight"></span>
</div>
 </div>
 <div className=''>
 <textarea placeholder='Send us a message' className=' border-2 lg:w-[400px] md:w-[200px] w-[360px] px-4 h-40 border-[#ccc] mt-4'>
 </textarea>
 <div className='flex justify-center items-center'>
 <h3>
  <div class="svg-wrapper-1">
    <div class="svg-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
          fill="currentColor"
          d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
        ></path>
      </svg>
    </div>
  </div>
  <h4><a href='mailto: nhcfghoffice@gmail.com'>Send</a></h4>
</h3>

 </div>
 </div>
      </div>
      </div>
      </div>
      </div>
      <div className=' grid grid-cols-1 md:hidden  bg-black/40 pt-8'>
        <div className=' flex justify-center items-center mb-4'>
        <div  className=' w-[370px] h-[200px] drop-shadow-2xl bg-white py-6  '>
            <div className=' flex justify-center items-center'>
              <div className=' w-16 h-16 border-2 border-green-600 rounded-full text-center flex justify-center items-center text-[40px] text-green-700'><SlLocationPin /></div>
              </div>
              <div className=' font-bold text-xl text-gray-700 opacity-75 py-2'>Our Address</div>
            <div>Kwame Nkrumah University of Science and Technology</div>
          </div>
        </div>
        <div className=' flex justify-center items-center mb-4'>
        <div  className=' w-[370px] h-[200px] drop-shadow-2xl bg-white py-6  '>
            <div className=' flex justify-center items-center'>
              <div className=' w-16 h-16 border-2 border-green-600 rounded-full text-center flex justify-center items-center text-[40px] text-green-700'><TfiEmail /></div>
              </div>
              <div className=' font-bold text-xl text-gray-700 opacity-75 py-2'>Email Us</div>
            <div> nhcfghoffice@gmail.com</div>
          </div>
        </div>
        <div className=' flex justify-center items-center mb-4'>
        <div  className=' w-[370px] h-[200px] drop-shadow-2xl bg-white py-6  '>
            <div className=' flex justify-center items-center'>
              <div className=' w-16 h-16 border-2 border-green-600 rounded-full text-center flex justify-center items-center text-[40px] text-green-700'><FiPhoneCall /></div>
              </div>
              <div className=' font-bold text-xl text-gray-700 opacity-75 py-2'>Call Us</div>
              <div>+233 50 472 3390</div>
            <div>+1 (602) 576-6168</div>
          </div>
        </div>
        <div className=' '>
      <div className=' flex justify-center items-center'>
      <div className='w-[390px] h-[490px] bg-white border-4 md:my-0 my-10 drop-shadow-2xl'>
        <div className='grid grid-cols-2 gap-2 py-5'>
          <div>
          <div class="input-container">
  <input placeholder="Your Name" class="input-field" type="text" />
  <label for="input-field" class="input-label">Your Name</label>
  <span class="input-highlight"></span>
</div>
          </div>
 <div>
 <div class="input-container">
  <input placeholder="Your Email" class="input-field" type="text" />
  <label for="Your Email" class="input-label">Your Email</label>
  <span class="input-highlight"></span>
</div>
 </div>
        </div>
        <div>
 <div class="input-container">
  <input placeholder="Subject" class="input-field" type="text" />
  <label for="input-field" class="input-label">Subject</label>
  <span class="input-highlight"></span>
</div>
 </div>
 <div className=''>
 <textarea placeholder='Send us a message' className='  w-[360px] px-4 h-40 border-[#ccc]'>
 </textarea>
 <div className='flex justify-center items-center'>
 <h3>
  <div class="svg-wrapper-1">
    <div class="svg-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
          fill="currentColor"
          d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
        ></path>
      </svg>
    </div>
  </div>
  <h4><a href='mailto: nhcfghoffice@gmail.com'>Send</a></h4>
</h3>

 </div>
 </div>
      </div>
      </div>
      </div>
      <div className=' pb-[500px]'>
      <MyMap /> 
      </div> 
      </div>
      <Donate />
    </div>
  )
}

export default Contact
