import React, { useState } from 'react'
import img1 from './workimages/nug2.jpg'
import img2 from './workimages/Knust.jpg'
import img4 from './workimages/src.jpg'
import img3 from './workimages/activite.jpg'
import { RxDotFilled } from "react-icons/rx";
import './overlay.css'
import knust from './workimages/Knust.jpg'
import nug from './workimages/nug2.jpg'
import src from './workimages/src.jpg'
import activity from './workimages/activite.jpg'
import non from './workimages/nugs1.png'
import graduate from './workimages/gras.jpg'
import './infinity.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Carousel = () => {
   const [data1,setedata1]=useState(img1);

   function change1(){
    setedata1(img2)
   }
   function change2(){
    setedata1(img3)
   }
   function change3(){
    setedata1(img1)
   }
   function change4(){
    setedata1(img4)
   }

   const [red,setred]=useState('white')

   function content(){
    if(data1==img1){
        setTimeout(()=>{
            setedata1(img2);
        },6000)
        return <div className=' hidden md:block'>
        <div className=' flex justify-center items-center lg:mt-40 md:mt-40 md:mr-40 mt-[70%] text-white'><div className=' duration-700 ease-in-out absolute'>
        <div> National Union of Ghana Students </div>
        <div className=' flex justify-center items-center'>
    < RxDotFilled className=" text-green-500 opacity-80 inline-block cursor-pointer" size={32} onClick={change1} />
       < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change2} />
       < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change3} />
       < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change4} /> 
       </div>
       </div>
       </div>
         </div>
        
    }
    if(data1==img2){
        setTimeout(()=>{
            setedata1(img3)
        },6000)
        return <div className=' hidden md:block'>
        <div className=' flex justify-center items-center lg:mt-40 md:mt-40 md:mr-40 mt-[70$] text-white'><div className=' duration-700 ease-in-out absolute'>
            <div>Kwame Nkrumah University of Science And Technology</div>
            <div className=' flex justify-center items-center'>
        < RxDotFilled className="  text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change1} />
           < RxDotFilled className=" text-green-500 inline-block cursor-pointer" size={32} onClick={change2} />
           < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change3} />
           < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer"  size={32} onClick={change4} /> 
           </div>
           </div>
           </div>
             </div>
        
    }
    if(data1==img3){
        setTimeout(()=>{
            setedata1(img4)
        },6000)
        return<div className=' hidden md:block'>
        <div className=' flex justify-center items-center lg:mt-40 md:mt-40 md:mr-40 mt-56 text-white'><div className=' duration-700 ease-in-out absolute'>
        <div>Associations of various Colleges in KNUST</div>
        <div className=' flex justify-center items-center'>
    < RxDotFilled className="  text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change1} />
       < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change2} />
       < RxDotFilled className=" text-green-500 inline-block cursor-pointer"  size={32} onClick={change3} />
       < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer"  size={32} onClick={change4} /> 
       </div>
       </div>
         </div>
         </div>
    }
    if(data1==img4){
        setTimeout(()=>{
            setedata1(img1)
        },6000)
        return <div className=' hidden md:block'>
        <div className=' flex justify-center items-center lg:mt-40 md:mt-40 md:mr-40 mt-56 text-white'> <div className=' duration-700 ease-in-out absolute'>
        <div> KNUST students Representative Council </div>
        
        <div className=' flex justify-center items-center'>
    < RxDotFilled className="  text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change1} />
       < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change2} />
       < RxDotFilled className=" text-white opacity-80 inline-block cursor-pointer" size={32} onClick={change3} />
       < RxDotFilled className=" text-green-500  opacity-80 inline-block cursor-pointer" size={32} onClick={change4} /> 
       </div>
       </div>
       </div>
         </div>
    }
   }

   useEffect(()=>{
    Aos.init({   offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,})
  },[])
  

return (
    <div>
      <div className=' bg-black/40'>
      <div className='lg:w-full w-full h-[300px] lg:mt-0 mt-2 relative' data-aos='flip-right' >
      <div className= ''></div>
      <div className=' flex justify-center items-center relative md:pt-20 '>
      <h1 className=' flex justify-center items-center absolute'>
      <div className=' text-white text-3xl top-10 md:mb-[108px] md:ml-64 mt-[50px] '>AFFILIATES</div>
        <div className=' flex justify-center items-center md:mr-96 md:-mx-40'>
          <div className=' flex justify-center items-center md:mx-4 lg:mt-16 md:mt-24 mt-[250px]'>
        <div className=' bg-white border-2 rounded-full w-[130px] h-[130px] lg:mt-[50px] md:mt-[10px] -ml-[130px] md:-ml-[10px] lg:-ml-0'>
            <div className=' flex justify-center items-center mt-1'>
            <img className=' rounded-full w-[120px] h-[120px] flex justify-center items-center' src={data1} />
            </div> 
            </div>    
        </div>
      </div>
      </h1>
      </div>
      <div>{content()}</div>
      </div>
      </div>
      <div className=' bg-white'>
      <div className='logos'>
        <div className='logos-slide'>
          <img src={knust} />
          <img src={graduate} />
          <img src={nug} />
          <img src={src} />
          <img src={non} />
          <img src={activity} />
          <img src={knust} />
          <img src={graduate} />
          <img src={nug} />
          <img src={src} />
          <img src={non} />
          <img src={activity} />
          <img src={knust} />
          <img src={graduate} />
          <img src={nug} />
          <img src={src} />
          <img src={non} />
          <img src={activity} />
          <img src={knust} />
          <img src={graduate} />
          <img src={nug} />
          <img src={src} />
          <img src={non} />
          <img src={activity} />
          <img src={knust} />
          <img src={graduate} />
          <img src={nug} />
          <img src={src} />
          <img src={non} />
          <img src={activity} />
          <img src={knust} />
          <img src={graduate} />
          <img src={nug} />
          <img src={src} />
          <img src={non} />
          <img src={activity} />
          <img src={knust} />
          <img src={graduate} />
          <img src={nug} />
          <img src={src} />
          <img src={non} />
          <img src={activity} />
        </div>
      </div>
      </div>
    </div>
  )
}

export default Carousel
