import './App.css';
import { Route,Routes } from 'react-router';
import Home from './componet/Home';
import About from './componet/About';
import Navbar from './componet/Navbar';
import Service from './componet/Service';
import Contact from './componet/Contact';
import Blog from './Blog';
import Download from './componet/Download';
import Loader from './componet/Loader';
import Content from './componet/Content';
import Form2 from './componet/Form2';
import Form1 from './componet/Form1';
import Land from './componet/Land';
import Content1 from './componet/Content1';
import Content2 from './componet/Content2';
import Content3 from './componet/Content3';
import Content4 from './componet/Content4';
import Content5 from './componet/Content5';
import Question from './componet/Question';
import { Campaign } from '@mui/icons-material';
import Content6 from './componet/Content6';
function App() {
  return (
    <div className="App">
    <Routes>
    <Route path='/' element={<Land />}  />
      <Route path='home' element={<Home />}  />
      <Route path='about' element={<About />} />
      <Route path='contact' element={<Contact />} />
      <Route path='campaign' element={<Service />} />
      <Route path='blog' element={<Question />} />
      <Route path='campaign/content' element={<Content />} />
      <Route path='campaign/content1' element={<Content1 />} />
      <Route path='campaign/content2' element={<Content2 />} />
      <Route path='campaign/content3' element={<Content3 />} />
      <Route path='campaign/content4' element={<Content4 />} />
      <Route path='campaign/content5' element={<Content5 />} />
      <Route path='campaign/content6' element={<Content6 />} />
    </Routes>
    </div>
  );
}
export default App;
